import { CloseOutlined } from "@mui/icons-material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import LoadingButton from "@mui/lab/LoadingButton"
import { Box, Button, Stack, SxProps } from "@mui/material"
import * as React from "react"

interface ModalContentProps {
  topChildren?: React.ReactNode
  children?: React.ReactNode
  hasBackButton?: boolean
  handleBackButton?: () => void
  hasActionButton?: boolean
  handleActionButton?: () => void
  actionButtonText?: string
  backButtonText?: string
  hasBottomCustomAction?: boolean
  sx?: SxProps
  loading?: boolean
  bottomChildren?: React.ReactNode
  positionTitle?: "space-between" | "flex-end"
  disabled?: boolean
}

export function ModalContent({
  topChildren,
  children,
  hasBottomCustomAction = false,
  bottomChildren,
  hasBackButton = true,
  handleBackButton,
  hasActionButton = true,
  handleActionButton,
  actionButtonText = "SALVAR E CONTINUAR",
  backButtonText = "FECHAR",
  sx,
  loading = false,
  positionTitle = "flex-end",
  disabled = false,
}: Readonly<ModalContentProps>) {
  return (
    <Stack
      sx={{
        flex: 1,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
        overflow: "auto",
        ...sx,
      }}
    >
      {hasBackButton && (
        <Box sx={{ justifyContent: positionTitle, alignItems: "start", display: "flex" }}>
          <Box
            sx={{
              display: "flex",

              width: "100%",
              color: "GrayText",
            }}
          >
            {topChildren}
          </Box>
          <Button
            startIcon={
              backButtonText === "FECHAR" ? <CloseOutlined /> : <ChevronLeftIcon />
            }
            onClick={handleBackButton}
            color="inherit"
          >
            {backButtonText}
          </Button>
        </Box>
      )}

      <Box flex={1} overflow={"auto"} borderRadius={"inherit"} marginY={1}>
        {children}
      </Box>

      {(hasBottomCustomAction || hasActionButton) && (
        <Box
          sx={{
            display: "flex",
            justifyContent:
              hasBottomCustomAction && hasActionButton
                ? "space-between"
                : hasActionButton
                  ? "flex-end"
                  : "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          {hasBottomCustomAction && <Box sx={{ flexGrow: 1 }}>{bottomChildren}</Box>}
          {hasActionButton && (
            <Box sx={{ ml: hasBottomCustomAction ? 2 : 0 }}>
              <LoadingButton
                id="btn-modal-action-button"
                variant="outlined"
                endIcon={<ChevronRightIcon />}
                onClick={handleActionButton}
                loading={loading}
                disabled={disabled}
                sx={{
                  color: "blueMsGov.dark",
                  border: `2px solid blueMsGov.main`,
                  borderColor: "blueMsGov.main",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 139, 0.1)",
                    borderColor: "blueMsGov.dark",
                  },
                  "&:active": {
                    backgroundColor: "blueMsGov.dark",
                    borderColor: "blueMsGov.light",
                  },
                  "&::before": {
                    content: '""',
                    backgroundColor: "blueMsGov.light",
                    opacity: 0.1,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                  },
                }}
              >
                {actionButtonText}
              </LoadingButton>
            </Box>
          )}
        </Box>
      )}
    </Stack>
  )
}
