import { ROUTES } from "@/interfaces/constants/routes"
import { useCurrentProfileStore } from "@/stores/data/useCurrentProfileStore"
import {
  isADMINProfileRole,
  isCandidateProfileRole,
  isCompanyProfileRole,
  isFuntrabProfileRole,
  isSemadescProfileRole,
  isStudentProfileRole,
} from "@/utils/profileUtils"

export async function selectPathnameService(redirect?: string) {
  if (redirect) {
    return redirect
  }

  const { currentProfile } = useCurrentProfileStore.getState()

  if (!currentProfile) {
    return ROUTES.HOME
  }

  if (isCandidateProfileRole(currentProfile)) {
    return ROUTES.PAINEL.CANDIDATE
  } else if (isStudentProfileRole(currentProfile)) {
    return ROUTES.PAINEL.STUDENT
  } else if (isCompanyProfileRole(currentProfile)) {
    return ROUTES.PAINEL.COMPANY
  } else if (isFuntrabProfileRole(currentProfile)) {
    return ROUTES.PAINEL.FUNTRAB
  } else if (isSemadescProfileRole(currentProfile)) {
    return ROUTES.PAINEL.SEMADESC
  } else if (isADMINProfileRole(currentProfile)) {
    return ROUTES.PAINEL.ADMIN
  }
  return ROUTES.HOME
}
