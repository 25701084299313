"use client"

import LoginButton from "@/components/ui/button/LoginButton"
import { LoadingAnimation } from "@/components/ui/loading/LoadingAnimation"
import HomeCardsSkeleton from "@/components/ui/skeleton/HomeCardsSkeleton"
import CompanyStoreTutorial from "@/containers/home-page/CompanyStoreTutorial"
import Footer from "@/containers/home-page/Footer"
import FrequentlyQuestions from "@/containers/home-page/FrequentlyQuestions"
import WelcomeSection from "@/containers/home-page/WelcomeSection"
import BaseHeader from "@/containers/home-page/header/BaseHeader"
import AccessModal from "@/containers/person/login/AccessModal"
import { useAuth } from "@/contexts/AuthProvider"
import MobileCheck from "@/contexts/MobileCheck"
import { useModalContext } from "@/contexts/ModalContext"
import { useProfiles } from "@/hooks/misc/useProfiles"
import { ROUTES } from "@/interfaces/constants/routes"
import { useMessageSnackbarStore } from "@/stores/ui/useMessageSnackbar"
import { roboto } from "@/styles/fonts"
import { isDevelopmentMode } from "@/utils/environmentUtils"
import { getAssetsPath } from "@/utils/getAssetsPathUtils"
import { getDefaultPanelOfHighestWeightedRole } from "@/utils/profileUtils"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Button, Container, Typography } from "@mui/material"
import dynamic from "next/dynamic"
import Image from "next/image"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { Suspense, useCallback, useEffect, useState } from "react"

const AnnouncementSection = dynamic(
  () => import("@/containers/home-page/AnnouncementSection"),
  { ssr: false },
)

const CardsSection = dynamic(() => import("@/containers/home-page/CardsSection"), {
  loading: () => <HomeCardsSkeleton />,
})

const MostPopularJobsSection = dynamic(
  () => import("@/containers/home-page/MostPopularJobsSection"),
  { ssr: false },
)

function Home() {
  const searchParams = useSearchParams()
  const refresh = searchParams.get("refresh")
  const redirect = searchParams.get("redirect")
  const { isLoggedIn } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const { profiles, isLoadingProfiles, isErrorProfiles } = useProfiles()
  const { errorMessage } = useMessageSnackbarStore()

  const handleRedirect = useCallback(
    (url: string) => {
      if (pathname !== url) {
        router.push(url)
      }
    },
    [pathname, router],
  )

  useEffect(() => {
    if (typeof window !== "undefined" && refresh) {
      const url = new URL(window.location.href)
      url.searchParams.delete("refresh")
      window.location.replace(url.toString())
    }
  }, [refresh])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    if (redirect) {
      handleRedirect(redirect)
      return
    }

    if (!isLoadingProfiles && !isErrorProfiles) {
      if (!profiles || profiles.length === 0) {
        errorMessage("Nenhum perfil disponível")
        return handleRedirect(ROUTES.PAINEL.CANDIDATE)
      }

      const highestWeightedRolePanel = getDefaultPanelOfHighestWeightedRole(profiles)

      if (!highestWeightedRolePanel) {
        errorMessage("Nenhum painel padrão disponível")
        return handleRedirect(ROUTES.PAINEL.CANDIDATE)
      }

      handleRedirect(highestWeightedRolePanel)
    }
  }, [
    redirect,
    isLoggedIn,
    handleRedirect,
    profiles,
    isLoadingProfiles,
    isErrorProfiles,
    errorMessage,
  ])

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)
    setIsMobile(checkMobile)
  }, [])

  function HomeApp() {
    const { openModal } = useModalContext()

    return (
      <Box
        sx={{
          backgroundImage: `url(${getAssetsPath()}/img/seta-branca.svg)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0% 20%",
          backgroundSize: "95vw auto",
          backgroundColor: "#EDEDED",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box maxWidth={"334px"}>
          <Box sx={{ maxWidth: "237px", margin: "auto" }}>
            <Image
              alt="Logo MS Qualifica"
              src={`${getAssetsPath()}/img/logo-ms-qualifica.svg`}
              width={80}
              height={80}
              priority={true}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>

          <Typography
            sx={{
              my: "20px",
              width: "100%",
              height: "117px",
              fontFamily: roboto.style.fontFamily,
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "30px",
              letterSpacing: "0.15px",
              textAlign: "center",
            }}
          >
            A sua porta de entrada para oportunidades no mercado de trabalho em Mato
            Grosso do Sul.
          </Typography>
          <LoginButton
            endIcon={<ChevronRightIcon />}
            altText="Entrar"
            buttonsSx={{
              width: "100%",
              color: "white",
              backgroundColor: "#009E3D",
              ":hover": {
                backgroundColor: "blueMsGov.dark",
              },
            }}
          />
          {isDevelopmentMode() && (
            <>
              <Button
                onClick={() => openModal("accessModal")}
                variant="outlined"
                id="btn-open-login-modal"
                sx={{ my: 2, width: "100%" }}
              >
                LOGIN MODO DEV
              </Button>
              <AccessModal />
            </>
          )}
          <MobileCheck />
        </Box>
      </Box>
    )
  }

  return (
    <>
      {isMobile && !isLoggedIn ? (
        <HomeApp />
      ) : (
        <>
          <BaseHeader isHome={true} profile={profiles} />
          <Container>
            <WelcomeSection />
            <CardsSection />
            <CompanyStoreTutorial />
            <MostPopularJobsSection />
            <FrequentlyQuestions />
          </Container>
          <AnnouncementSection />
          <Footer />
        </>
      )}
    </>
  )
}

export default function HomePage() {
  return (
    <Suspense fallback={<LoadingAnimation text="Carregando a página inicial..." />}>
      <Home />
      <MobileCheck />
    </Suspense>
  )
}
