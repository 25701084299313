import { roboto } from "@/styles/fonts"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Alert, AlertTitle, Typography } from "@mui/material"
import * as React from "react"

interface CustomAlertProps {
  title?: string
  body?: React.ReactNode
  backgroundColor?: string
  textColor?: string
}
export function CustomAlert({
  title,
  body,
  backgroundColor = "#E5F6FD",
  textColor = "#014361",
}: CustomAlertProps) {
  return (
    <>
      <Alert
        icon={<InfoOutlinedIcon fontSize="inherit" sx={{ color: textColor }} />}
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: 0.5,
        }}
      >
        {title && (
          <AlertTitle>
            <Typography
              color={textColor}
              fontSize={"1rem"}
              fontWeight={700}
              fontFamily={roboto.style.fontFamily}
            >
              {title}
            </Typography>
          </AlertTitle>
        )}
        {body && (
          <Typography
            color={textColor}
            fontSize={"0.875rem"}
            fontFamily={roboto.style.fontFamily}
          >
            {body}
          </Typography>
        )}
      </Alert>
    </>
  )
}
