"use client"

import { setStateGov } from "@/cookies/stateGovHelper"
import { Button, SxProps, Theme } from "@mui/material"
import crypto from "crypto"

interface LoginButtonProps {
  buttonsSx?: SxProps<Theme>
  adictionalRedirect?: string
  altText?: string
  fullWidth?: boolean
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  id?: string
  variant?: "text" | "outlined" | "contained" | undefined
}

export default function LoginButton({
  buttonsSx,
  altText,
  fullWidth,
  startIcon,
  endIcon,
  id,
  variant,
}: LoginButtonProps) {
  const clientId = process.env.NEXT_PUBLIC_AUTH_GOV_CLIENT_ID
  const state = crypto.randomBytes(16).toString("hex")
  const redirectFailed = process.env.NEXT_PUBLIC_AUTH_GOV_REDIRECT_FAILED
  const govRedirect = process.env.NEXT_PUBLIC_AUTH_GOV_REDIRECT

  setStateGov(state)

  const handleAuthGOV = () => {
    window.location.href = `${govRedirect}/sso/cidadao?client_id=${clientId}&state=${state}&redirect_failed=${redirectFailed}`
  }

  return (
    <Button
      onClick={handleAuthGOV}
      fullWidth={fullWidth}
      variant={variant ? variant : "contained"}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        backgroundColor: "white",
        color: "blueMsGov.main",
        borderColor: "white",
        textTransform: "uppercase",
        ":hover": {
          backgroundColor: "rgba(255, 255, 255, 0.75)",
        },
        ...buttonsSx,
      }}
      id={id ? id : "btn_entrar"}
    >
      {altText ? altText : "Entrar"}
    </Button>
  )
}
