import { PasswordRecoveryInput } from "@/interfaces/login/PasswordRecoveryInput"
import {
  api,
  getErrorMessageFromResponseData,
  showErrorMessage,
  showSuccessMessage,
} from "@/libs/api"
import { AxiosError } from "axios"

export async function passwordRecoveryService(input: PasswordRecoveryInput) {
  try {
    const response = await api.post(
      `${process.env.NEXT_PUBLIC_AUTH_SERVICE_PATH}/user/recovery`,
      input,
    )
    if (response.status === 200) {
      showSuccessMessage(
        "Instruções de recuperação de senha foram enviadas para o seu e-mail.",
      )
    }
    return response.data
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      const message = getErrorMessageFromResponseData(error.response.data)
      showErrorMessage(message)
    } else {
      showErrorMessage("Um erro inesperado ocorreu. Por favor, tente novamente.")
    }
  }
}
