import { useAuth } from "@/contexts/AuthProvider"
import { EnumData } from "@/interfaces/EnumData"
import { WorkerHouseOutput } from "@/interfaces/funtrab/WorkerHouseOutput"
import { PersonBase } from "@/interfaces/person/PersonBase"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { useEffect, useState } from "react"
import useSWR, { mutate } from "swr"

export function useProfiles() {
  const [cachedData, setCachedData] = useState<{
    person: PersonBase | null
    profiles: ProfileOutput[]
    workerHouses: WorkerHouseOutput[]
    subRoles: EnumData[]
  } | null>(null)

  const { isLoggedIn, isLoading: isAuthLoading } = useAuth()
  const [isHydrated, setIsHydrated] = useState(false)

  const shouldFetch = isHydrated && isLoggedIn && !isAuthLoading

  const {
    data,
    error,
    isLoading,
    isValidating,
    mutate: mutateRefresh,
  } = useSWR(
    isHydrated && isLoggedIn
      ? `${process.env.NEXT_PUBLIC_EMPLOYMENT_SERVICE_PATH}/user/profile`
      : null,
    {
      onError: (error) => {
        console.error("Falha ao buscar perfis:", error)
      },
      dedupingInterval: 60000,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  useEffect(() => {
    if (isLoggedIn && !isAuthLoading) {
      mutate("/user/profile")
    }
  }, [isLoggedIn, isAuthLoading])
  useEffect(() => {
    if (isLoggedIn && !isAuthLoading) {
      mutate("/user/profile")
    }
  }, [isLoggedIn, isAuthLoading])

  useEffect(() => {
    if (!isAuthLoading) {
      setIsHydrated(true)
    }
  }, [isAuthLoading, shouldFetch, isHydrated])

  useEffect(() => {
    if (data) {
      setCachedData({
        person: data.person,
        profiles: data.profiles,
        workerHouses: data.workerHouses || [],
        subRoles: data.subRoles || [],
      })
    }
  }, [data])

  const person: PersonBase | null = data?.person ?? cachedData?.person ?? null
  const profiles: ProfileOutput[] = data?.profiles ?? cachedData?.profiles ?? []
  const workerHouses: WorkerHouseOutput[] =
    data?.workerHouses ?? cachedData?.workerHouses ?? []
  const subRoles: EnumData[] = data?.subRoles ?? cachedData?.subRoles ?? []

  return {
    person,
    profiles,
    mutateRefresh,
    workerHouses,
    subRoles,
    isLoadingProfiles: !isHydrated || isLoading || isAuthLoading,

    isErrorProfiles: !!error,
    isValidatingProfiles: isValidating,
    mutateProfiles: () =>
      mutate(`${process.env.NEXT_PUBLIC_EMPLOYMENT_SERVICE_PATH}/user/profile`),
  }
}
