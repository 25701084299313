"use client"

import WidgetLibras from "@/components/ui/accessibility/WidgetLibras"
import { MessageSnackbar } from "@/components/ui/snackbar/MessageSnackbar"
import { AuthProvider } from "@/contexts/AuthProvider"
import { ModalProvider } from "@/contexts/ModalContext"
import { SWRConfigProvider } from "@/contexts/SWRConfigContext"
import { ROUTES } from "@/interfaces/constants/routes"
import {
  ADMINtheme,
  FUNTRABTheme,
  IEPtheme,
  SEMADESCtheme,
  applicantsTheme,
  baseTheme,
  employerTheme,
} from "@/styles/theme"
import { isDevelopmentMode } from "@/utils/environmentUtils"
import { CssBaseline } from "@mui/material"
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter"
import { Theme, ThemeProvider } from "@mui/material/styles"
import { usePathname } from "next/navigation"
import Script from "next/script"
import React, { memo } from "react"

function InitialTheme(): Theme {
  const pathname = usePathname()

  const themeMap: Readonly<Record<string, Theme>> = {
    [ROUTES.PAINEL.CANDIDATE]: applicantsTheme,
    [ROUTES.PAINEL.STUDENT]: applicantsTheme,
    [ROUTES.PAINEL.COMPANY]: employerTheme,
    [ROUTES.PAINEL.FUNTRAB]: FUNTRABTheme,
    [ROUTES.PAINEL.SEMADESC]: SEMADESCtheme,
    [ROUTES.PAINEL.IEP]: IEPtheme,
    [ROUTES.PAINEL.ADMIN]: ADMINtheme,
  }

  for (const route in themeMap) {
    if (pathname.startsWith(route)) {
      return themeMap[route]
    }
  }

  return baseTheme
}

export function ClientLayout({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const gtmId = process.env.NEXT_PUBLIC_G_TAG_MANAGER_ID ?? ""
  return (
    <>
      <noscript>
        <iframe
          title="Google Tag Manager (noscript)"
          id="gtm-body"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          loading="lazy"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <AppRouterCacheProvider>
        <ThemeProvider theme={InitialTheme()}>
          <CssBaseline />
          <AuthProvider>
            <ModalProvider>
              <SWRConfigProvider>
                <div id="message-spot" aria-live="polite"></div>
                <main>{children}</main>
                <MessageSnackbar />
              </SWRConfigProvider>
            </ModalProvider>
          </AuthProvider>
        </ThemeProvider>
      </AppRouterCacheProvider>
      {!isDevelopmentMode() && (
        <>
          <Script
            src="https://cdn.userway.org/widget.js"
            data-account="nZyGsommUy"
            async
          ></Script>
          <WidgetLibras />
        </>
      )}
    </>
  )
}

export default memo(ClientLayout)
